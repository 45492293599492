<template>
    <agile :autoplay="true" :autoplaySpeed="5000">
        <div class="slide" v-for="slide in slides" :key="slide.id">
            <video v-if="slide.video" :src="getImagePath(slide.video)" autoplay loop controls muted></video>
            <image-component v-else-if="slide.image" :path="slide.image" />
        </div>
    </agile>
</template>



<script>
    import { VueAgile } from 'vue-agile'
    import ImageComponent from "../../../components/base-components/ImageComponent";

    export default {
        name: "SliderComponent",
        components: {
          ImageComponent,
            agile: VueAgile
        },
        props: {
            slides: Array
        },
    }
</script>

<style lang="sass">
    @import "../../../assets/css/colors"
    .agile
        &__nav-button
            background: transparent
            border: none
            color: $text-secondary
            cursor: pointer
            font-size: 24px
            height: 100%
            position: absolute
            top: 0
            transition-duration: .3s
            width: 80px

            &:hover
                background-color: rgba($button-primary-rgb, .5)
                opacity: 1

            &--prev
                left: 0

            &--next
                right: 0

        &__dots
            bottom: 10px
            left: 50%
            position: absolute
            transform: translateX(-50%)

        &__dot
            margin: 0 10px

            button
                background-color: transparent
                border: 1px solid $secondary-background
                border-radius: 50%
                cursor: pointer
                display: block
                height: 10px
                font-size: 0
                line-height: 0
                margin: 0
                padding: 0
                transition-duration: .3s
                width: 10px

            &--current,
            &:hover
                button
                    background-color: $secondary-background

    // Slides styles
    .slide
        display: block
        height: 500px
        object-fit: cover
        width: 100%
</style>
